<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import { useVuelidate } from "@vuelidate/core";
import type { ValidationRuleWithoutParams } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import type { CmsElementForm } from "@shopware/composables";
import { useCmsTranslations } from "@shopware/composables";
import { ApiClientError } from "@shopware/api-client";
import type { ApiError } from "@shopware/api-client";
import { useCmsElementConfig, useNewsletter, useSalutations } from "#imports";
import { computed, reactive, ref } from "vue";
import { defu } from "defu";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

type Translations = {
  form: {
    subscribeLabel: string;
    unsubscribeLabel: string;
    action: string;
    email: string;
    salutation: string;
    salutationPlaceholder: string;
    firstName: string;
    firstNamePlaceholder: string;
    lastName: string;
    lastNamePlaceholder: string;
    privacy: string;
    privacyLabel: string;
    submit: string;
  };
};

let translations: Translations = {
  form: {
    subscribeLabel: "Subscribe to newsletter",
    unsubscribeLabel: "Unsubscribe from newsletter",
    action: "Action",
    email: "Email address",
    salutation: "Salutation",
    salutationPlaceholder: "Enter salutation...",
    firstName: "First name",
    firstNamePlaceholder: "Enter first name...",
    lastName: "Last name",
    lastNamePlaceholder: "Enter last name...",
    privacy: "Privacy",
    privacyLabel: "I have read the data protection information.",
    submit: "Abonnieren",
  },
};

translations = defu(useCmsTranslations(), translations) as Translations;

const loading = ref<boolean>();
const formSent = ref<boolean>(false);
const errorMessages = ref<ApiError[]>([]);
const subscriptionOptions: {
  label: string;
  value: "subscribe" | "unsubscribe";
}[] = [
  {
    label: translations.form.subscribeLabel,
    value: "subscribe",
  },
  {
    label: translations.form.unsubscribeLabel,
    value: "unsubscribe",
  },
];
const { getSalutations } = useSalutations();
const { newsletterSubscribe, newsletterUnsubscribe } = useNewsletter();

const state = reactive({
  option: subscriptionOptions[0].value,
  salutationId: "",
  firstName: "",
  lastName: "",
  email: "",
  checkbox: true,
});

watch(getSalutations, (newValue) => {
  state.salutationId = newValue[newValue.length - 1].id;
});

type Rules = {
  email: {
    required: ValidationRuleWithoutParams;
    email: ValidationRuleWithoutParams;
  };
  checkbox: {
    required: ValidationRuleWithoutParams;
    isTrue: (value: boolean) => boolean;
  };
  firstName: {
    required: ValidationRuleWithoutParams;
    minLength: number;
  };
  lastName: {
    required: ValidationRuleWithoutParams;
    minLength: number;
  };
};
const rules = computed(() => {
  let temp: Partial<Rules> = {
    email: {
      required,
      email,
    },
    checkbox: {
      required,
      isTrue: (value: boolean) => value === true,
    },
  };
  if (state.option === "subscribe") {
    temp = {
      ...temp,
    };
  }
  return temp;
});

const $v = useVuelidate(rules, state);
const newsletterSubmitted = ref(false);
const invokeSubmit = async () => {
  newsletterSubmitted.value = true;
  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid) {
    loading.value = true;
    try {
      if (state.option === "subscribe") {
        await newsletterSubscribe({
          ...state,
        });
      } else {
        await newsletterUnsubscribe(state.email);
      }
      formSent.value = true;
      setTimeout(() => {
        formSent.value = false;
        state.email = "";
      }, 3000); // 10,000 milliseconds = 10 seconds
    } catch (e) {
      if (e instanceof ApiClientError) {
        errorMessages.value = e.details.errors;
      }
    } finally {
      loading.value = false;
    }
  }
  newsletterSubmitted.value = false;
};
</script>

<template>
  <section class="bg-skin-theme text-skin-white py-10 lg:py-[5.125rem]">
    <div class="container">
      <div class="flex flex-wrap gap-y-9 items-center text-center lg:text-left">
        <div class="w-full lg:w-6/12">
          <h4 class="text-base font-bold">{{ $t("footer.newsletter") }}</h4>
        </div>
        <div class="w-full lg:w-6/12 md:flex md:justify-center lg:justify-end">
          <form @submit.prevent="invokeSubmit">
            <template v-if="!formSent">
              <div
                class="flex w-full md:w-[29.7rem] relative mx-auto lg:float-right border  rounded-r-[30px] rounded-l-[3px]"
                :class="[
                    $v.email?.$error && newsletterSubmitted
                      ? 'border-red-500'
                      : 'border-skin-white',
                  ]"
              >
                <div
                  v-if="loading"
                  class="absolute inset-0 w-full h-10 flex items-center justify-center z-10 mt-3"
                >
                  <div class="relative w-6 h-6">
                    <div
                      class="absolute top-0 left-0 w-6 h-6 border-2 border-extra-light-grey rounded-full"
                    ></div>
                    <div
                      class="absolute top-0 left-0 w-6 h-6 border-2 border-red-bg rounded-full animate-spin"
                      style="
                        border-top-color: transparent;
                        animation-duration: 0.5s;
                      "
                    ></div>
                  </div>
                </div>
                <input
                  id="email-address-newsletter"
                  v-model="state.email"
                  name="email"
                  type="email"
                  required
                  autocomplete="email"
                  class="min-w-[50%] h-[3.44rem] pl-5 xl:pr-14 pt-4 text-skin-white placeholder:text-skin-white bg-[transparent] !ring-transparent flex-1 border-0 bg-search-white bg-no-repeat bg-left-center-5 pl-[2.875rem]"
                  :placeholder="$t('footer.newsletterPlaceholder')"
                  @blur="$v.email?.$touch()"
                />
                <button
                  class="h-[3.44rem] px-[1.5rem] xl:px-[2.2rem] py-[1.15rem] text-skin-theme text-sm uppercase rounded-full bg-skin-white font-Helvetica cursor-pointer font-bold right-0"
                  type="submit"
                >
                  {{ $t("footer.newsletterSubmit") }}
                </button>
                <div class="hidden">
                  <div class="col-span-12">
                    <label for="option">{{ translations.form.action }} *</label>
                    <select
                      id="option"
                      v-model="state.option"
                      name="option"
                      class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 focus:z-10 sm:text-sm"
                    >
                      <option
                        v-for="subscription in subscriptionOptions"
                        :key="subscription.value"
                        :value="subscription.value"
                      >
                        {{ subscription.label }}
                      </option>
                    </select>
                  </div>
                  <div v-if="state.option === 'subscribe'" class="col-span-4">
                    <label for="salutation"
                      >{{ translations.form.salutation }} *</label
                    >
                    <select
                      id="salutation"
                      v-model="state.salutationId"
                      name="salutation"
                      class="appearance-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:z-10 sm:text-sm"
                      :class="[
                        $v.salutationId?.$error
                          ? 'border-red-600 focus:border-red-600'
                          : 'border-gray-300 focus:border-gray-500',
                      ]"
                      @blur="$v.salutationId?.$touch()"
                    >
                      <option
                        v-for="salutation in getSalutations"
                        :key="salutation.id"
                        :value="salutation.id"
                      >
                        {{ salutation.displayName }}
                      </option>
                    </select>
                    <span
                      v-if="$v.salutationId?.$error"
                      class="pt-1 text-sm text-red-600 focus:ring-brand-primary border-gray-300"
                    >
                      {{ $v.salutationId?.$errors[0].$message }}
                    </span>
                  </div>
                  <div v-if="state.option === 'subscribe'" class="col-span-4">
                    <label for="first-name"
                      >{{ translations.form.firstName }} *</label
                    >
                    <input
                      id="first-name"
                      v-model="state.firstName"
                      name="first-name"
                      type="text"
                      autocomplete="first-name"
                      class="appearance-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:z-10 sm:text-sm"
                      :class="[
                        $v.firstName?.$error
                          ? 'border-red-600 focus:border-red-600'
                          : 'border-gray-300 focus:border-gray-500',
                      ]"
                      :placeholder="translations.form.firstNamePlaceholder"
                      @blur="$v.firstName?.$touch()"
                    />
                    <span
                      v-if="$v.firstName?.$error"
                      class="pt-1 text-sm text-red-600 focus:ring-brand-primary border-gray-300"
                    >
                      {{ $v.firstName?.$errors[0].$message }}
                    </span>
                  </div>
                  <div v-if="state.option === 'subscribe'" class="col-span-4">
                    <label for="last-name"
                      >{{ translations.form.lastName }} *</label
                    >
                    <input
                      id="last-name"
                      v-model="state.lastName"
                      name="last-name"
                      type="text"
                      autocomplete="last-name"
                      class="appearance-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:z-10 sm:text-sm"
                      :class="[
                        $v.lastName?.$error
                          ? 'border-red-600 focus:border-red-600'
                          : 'border-gray-300 focus:border-gray-500',
                      ]"
                      :placeholder="translations.form.lastNamePlaceholder"
                      @blur="$v.lastName?.$touch()"
                    />
                    <span
                      v-if="$v.lastName?.$error"
                      class="pt-1 text-sm text-red-600 focus:ring-brand-primary border-gray-300"
                    >
                      {{ $v.lastName?.$errors[0].$message }}
                    </span>
                  </div>
                  <div class="col-span-12">
                    <label>{{ translations.form.privacy }} *</label>
                    <div class="flex gap-3 items-start">
                      <input
                        id="privacy"
                        v-model="state.checkbox"
                        name="privacy"
                        type="checkbox"
                        class="mt-1 focus:ring-gray-500 h-4 w-4 border text-gray-600 rounded"
                        :class="[
                          $v.checkbox?.$error
                            ? 'border-red-600'
                            : 'border-gray-300',
                        ]"
                      />
                      <div>
                        <label
                          :class="[$v.checkbox?.$error ? 'text-red-600' : '']"
                          for="privacy"
                        >
                          {{ translations.form.privacyLabel }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="text-base md:text-lg text-white mb-9 italic">
                {{ $t("footer.newsletterBenefits") }}
              </p>
            </template>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
